//PasswordReset.vue

<template>
	<div class="text-center">
		<form class="form-forgot" @submit.prevent="submitForm">
			<!-- <img style="display: block;" class="logo" width="200" src="../assets/rtNOW-logo.png"> -->

			<h1 class="mb-3 mt-5 text-left">Need a new password?</h1>

			<p class="lead text-muted text-left">
				Enter your email address and recieve instructions to create a new
				password.
			</p>

			<label for="inputEmail" class="sr-only">Email address</label>

			<input
				v-model="email"
				type="email"
				id="inputEmail"
				class="form-control form-control-lg"
				placeholder="Email address"
				required=""
				autofocus=""
			/>

			<button
				class="btn btn-lg btn-primary btn-block mt-4"
				:class="{ 'btn-loading': ajaxLoader }"
				type="submit"
			>
				Send
			</button>

			<router-link to="/login" class="btn btn-lg mt-3">Login</router-link>

			<p class="mt-5 mb-3 text-muted">© 2017-2018</p>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			email: ""
		};
	},

	computed: {
		...mapGetters(["loggedIn", "ajaxLoader"]),

		moreComputedProperties() {
			return "foo";
		}
	},

	methods: {
		...mapActions(["ajax", "logout", "globalAlert"]),

		submitForm() {
			console.log(this.email);

			this.ajax({
				pushLogin: false,
				url: "/authentication/send_reset_password",
				data: {
					email: this.email
				},
				success: json => {
					if (json.error) {
						//alert(json.error);
						this.globalAlert({
							title: "Error",
							body: json.error
						});

						return false;
					} else {
						//alert('Check your inbox for instructions to reset your password.');
						this.globalAlert({
							title: "Email Sent",
							body: "Check your inbox for instructions to reset your password."
						});
						this.$router.push("/welcome");
					}
				}
			});
		} //submitForm
	},

	mounted() {
		this.logout();

		//this.setDestination('/welcome');
	}
};
</script>

<style lang="scss">
.form-forgot {
	width: 100%;
	max-width: 430px;
	padding: 15px 10px;
	margin: 0 auto;
}
.form-forgot .checkbox {
	font-weight: 400;
}
.form-forgot .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	//padding: 10px;
	//font-size: 16px;
}
.form-forgot .form-control:focus {
	z-index: 2;
}
</style>

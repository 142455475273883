<template>
	<div class="text-center">
		<form class="form-password" @submit.prevent="submitForm">
			<h1 class="mb-4 mt-5 text-left">Create a new password</h1>

			<pre class="text-left">
token: {{ token }}
new_password: {{ new_password }}
confirmPassword: {{ confirmPassword }}</pre
			>

			<input type="hidden" id="token" name="token" v-model="token" />

			<section v-if="formErrors">
				<div
					v-for="(key, value) in formErrors"
					:key="value + '-password-reset-form-errors'"
					class="alert alert-danger"
					role="alert"
				>
					{{ key }}
				</div>
			</section>

			<input
				v-model="new_password"
				type="password"
				id="password"
				class="form-control form-control-lg"
				placeholder="Enter New Password"
				required
				autofocus
			/>

			<input
				v-model="confirmPassword"
				type="password"
				id="confirmPassword"
				class="form-control form-control-lg"
				placeholder="Confirm New Password"
				required
			/>

			<button
				:disabled="!matching"
				class="btn btn-lg btn-primary btn-block mt-4"
				:class="{ 'btn-loading': ajaxLoader }"
				type="submit"
			>
				Save
			</button>

			<router-link to="/login" class="btn btn-lg mt-3">Login</router-link>

			<p class="mt-5 mb-3 text-muted">© 2017-2018</p>
		</form>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			new_password: "",
			confirmPassword: "",
			token: this.$route.params.token,
			formErrors: false
		};
	},

	computed: {
		...mapGetters(["loggedIn", "ajaxLoader"]),

		matching() {
			if (this.new_password == "") {
				return false;
			}

			return this.new_password == this.confirmPassword;
		},

		moreComputedProperties() {
			return "foo";
		}
	},

	// beforeRouteLeave (to, from, next) {
	//   // called when the route that renders this component is about to
	//   // be navigated away from.
	//   // has access to `this` component instance.
	//   if(!this.formErrors){
	//     next();
	//   }
	//
	// },

	mounted() {
		this.logout();

		this.setDestination("/reports");
	},

	methods: {
		...mapActions([
			//'login',
			"ajax",
			"logout",
			"setDestination"
		]),

		submitForm() {
			console.log(this.password);
			console.log(this.confirmPassword);

			this.formErrors = false;

			this.ajax({
				pushLogin: false,
				url: "/authentication/update_password",
				data: {
					new_password: this.new_password,
					confirm_new_password: this.confirmPassword,
					token: this.token
				},
				success: json => {
					// has errors
					if (json.error) {
						var c = confirm(json.error);

						if (c) {
							//this.$set(this, 'formErrors', false);
							setTimeout(() => {
								//this.$set(this, 'formErrors', false);
								this.$router.replace("/password/forgot");
							}, 500);
						}
					} else if (json.validation_errors) {
						this.formErrors = json.validation_errors;
					} else {
						//this.$set(this, 'formErrors', false);
						this.$router.replace("/login");
					}
				}
			});
		} //submitForm
	}
};
</script>

<style lang="scss">
.form-password {
	width: 100%;
	max-width: 430px;
	padding: 15px 10px;
	margin: 0 auto;
}
.form-password .checkbox {
	font-weight: 400;
}
.form-password .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	//padding: 10px;
	//font-size: 16px;
}
.form-password .form-control:focus {
	z-index: 2;
}
.form-password input[id="password"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-password input[id="confirmPassword"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
</style>
